import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {getMainEvent} from '../../checkout/selectors/event'
import Translation from "../../shared/Translation";

class SalesPaused extends Component<SalesPausedProps> {
  render() {
    if (!this.props.isPaused) {
      return null;
    }

    return (
      <div className="event-details__sales-paused">
        <Translation>{'checkout__online_sales_are_currently_paused'}</Translation>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const mainEvent = getMainEvent(state);
  return {
    locale: state.intl.locale,
    isPaused: !!mainEvent?.event?.isPaused,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
}

SalesPaused.propTypes = {
  locale: PropTypes.string.isRequired,
  isPaused: PropTypes.bool.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesPaused)
