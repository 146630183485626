import { createSelector } from 'reselect';
import { getSeriesOption } from '../views/series/selectors/eventSeries';
import { hasSelectedChildEvent } from '../views/series/selectors/eventSeries';
import {EMBED_CART_BEHAVIOR_HIDE, EMBED_TYPE_MULTI, TICKET_FEE_SEPARATE, TICKET_PRICE_ONLY} from '../reducers/ui';
import { POSITION_EMBEDDED_IN_REGISTRATION } from '../views/upsells/reducers/upsells';
import { isPyos, isSeatedListing, showPyosPicker, hasAppliedReserveCoupon } from './reserved-seating';
import { EMBED_CART_BEHAVIOR_PIN_TO_TOP, EMBED_CART_BEHAVIOR_PIN_TO_BOTTOM } from '../reducers/ui';
import { areAllQuestionsCompleted } from './questions';

const getSteps = state => Array.isArray(state.ui.listingConfig?.checkoutSteps) ? state.ui.listingConfig.checkoutSteps : [];
const getCurrentStep = state => state.ui.currentStep;
export const getCaptureMethod = state => state.payment.method;
const getPurchaseUser = state => state.reservation.purchaseUser;
const getTerminalId = state => state.payment.terminalId;
const getTerminalConnection = state => state.payment.isReaderConnected;
const seriesConfig = state => state.ui.checkoutConfig.seriesOptions;
export const getListingConfig = state => state.ui && state.ui.listingConfig ? state.ui.listingConfig : {};
const getUiConfig = state => state.ui.uiConfig;
const getCheckoutConfig = state => state.ui && state.ui.checkoutConfig ? state.ui.checkoutConfig : {};
const isCalendarOnListing = state => getSeriesOption("calendarOnListing", false)(state);
const getRouter = state => state.router;
const getAccessInventory = state => state.coupon.accessInventory;
const getEvent = state => state.event;
const getAllState = state => state;
const getUIState = state => state.ui;
const getIsEmbed = state => !!state.ui.isEmbed;
export const getEmbedCartBehavior = state => state.ui?.uiConfig?.listings?.embedCartBehavior;
const reservationCompleted = state => state.reservation.isCompleted === true;
const overlay = state => state.ui.overlay;
const hasReservation = state => state.reservation.token !== "";

export const showStatusBar = (isEmbeddedPicker = false) => {
    return createSelector(
        [getAllState],
        (allState) => {
            const ticketCount = allState.reservation?.ticket_count ? +allState.reservation.ticket_count : 0;
            return !isEmbeddedPicker && (ticketCount >= 1 || hasAppliedReserveCoupon(allState)) && !showPyosPicker(allState);
        }
    )
}

export const isMultiEmbed = createSelector(
  [getUIState],
  (ui) => !!ui.isEmbed && ui.embedType === EMBED_TYPE_MULTI
)

export const allStepsComplete = createSelector(
    [getSteps, getListingConfig],
    (steps, config) => {
        let complete = true;
        for(let i = 0; i < config.checkoutSteps.length; i++) {
            if(config.checkoutSteps[i].complete !== true) {
                complete = false;
                break;
            }
        }
        return complete;
    }
)

export const getCurrentCheckoutStep = createSelector(
    [getSteps, getCurrentStep],
    (steps, currentStepIndex) => {
        return Array.isArray(steps) && steps.length > currentStepIndex ? steps[currentStepIndex] : null;
    }
)

export const inLastStep = createSelector(
    [getSteps, getCurrentStep],
    (steps, currentStepIndex) => {
        return Array.isArray(steps) && currentStepIndex === steps.length - 1;
    }
)

export const getBlockingErrors = createSelector(
    [
        getCaptureMethod,
        getPurchaseUser,
        getTerminalId,
        getTerminalConnection,
        getListingConfig
    ],
    (
        method,
        purchaseUser,
        terminalId,
        terminalConnected,
        config
    ) => {
        let errors = [];
        // corresponds to CAPTURE_KIOSK
        if(method === 13) {
            let cmsMode = getCmsModeFromConfig(config);
            if(!cmsMode) {
                if (!purchaseUser || purchaseUser == 0) {
                    errors.push("checkout__kiosk_user_must_be_set");
                }

                if(!isDevMode()) {
                    if (+terminalId === 0) {
                        errors.push("checkout__no_emv_terminal_selected");
                    } else if (!terminalConnected) {
                        errors.push("checkout__emv_terminal_is_disconnected");
                    }
                }
            }
        }

        return errors;
    }
);

export const isKioskMode = createSelector(
    [
        getCaptureMethod,
    ],
    (
        captureMethod
    ) => {
        return isCaptureMethodKioskMode(captureMethod);
    }
);

const isCaptureMethodKioskMode = captureMethod => captureMethod === 13;

export const getSeriesConfig = createSelector(
    [
        seriesConfig,
    ],
    (
        config
    ) => {
        if(config) {
            return config;
        }

        return {};
    }
);

export const getListingSettings = createSelector(
    [
        getListingConfig,
        isCalendarOnListing,
        getIsEmbed,
    ],
    (
        config,
        calendarOnListing,
        isEmbed,
    ) => {
        let settings = {};
        if (!isEmbed) {
            if(config && config.listing) {
                settings = config.listing;
            } else if (calendarOnListing) {
                settings = getListingSettingsWithCalendar();
            } else {
                settings = getDefaultListingSettings(config);
            }
        }
        return settings;
    }
);

function getDefaultListingSettings(config) {
    if(config.layout === "kiosk") {
        return {
            components: [
                {
                    name: 'continueCheckoutBanner',
                },
                {
                    name: 'KioskListing',
                    children: [
                        {
                            name: "SoldOutDayDisplay",
                        },
                        {
                        name: 'ticketsButton',
                            props: {type: 'banner__touch'},
                            children: [
                                {
                                    name: "NextAvailableTime",
                                },
                            ]
                        },
                        {
                            name: "TicketsGoOnSale",
                        }
                    ]
                },
                {
                    name: "KioskManagement",
                },
                {
                    name: "KioskMonitoring",
                    props: {
                        // 'interval' => 1000 // in ms
                    }
                },
                {
                    name: "TimeClock",
                },
                {
                    name: "RefreshListingData",
                },
                {
                    name: "KioskTimeContainer",
                },
                {
                    name: "TimeoutPromptContainer",
                },
            ]
        }
    } else {
        return {
            components: [
                {
                    name: 'continueCheckoutBanner',
                },
                {
                    name: 'listingBanner',
                    children: [
                        {
                            name: 'ticketsButton',
                        }
                    ]
                },
                {
                    name: 'listingDetails',
                },
                {
                    name: 'contactInfo',
                },
                {
                    name: 'footer',
                },
            ],
        }
    }
}

function getListingSettingsWithCalendar() {
    return {
        components: [
            {
                name: 'continueCheckoutBanner',
            },
            {
                name: 'listingBanner',
                children: [
                    {
                        name: 'listingDatePicker',
                    }
                ]
            },
            {
                name: 'listingDetails',
            },
            {
                name: 'contactInfo',
            },
            {
                name: 'footer',
            },
        ],
    };
}

export const getCheckoutStepsSettings = createSelector(
    [
        getListingConfig,
        getCurrentStep,
    ],
    (
        config,
        currentStep
    ) => {
        let settings = {};
        if(config && config.checkoutSteps) {
            settings = config.checkoutSteps[currentStep];
        } else {
            const steps = getDefaultCheckoutStepSettings();
            settings = steps[currentStep];
        }

        return settings;
    }
);

export function getDefaultCheckoutStepSettings(config, hasQuestions, hasDeliveryOptions, isFree) {
    if(config.layout === "kiosk") {
        let checkoutSteps = [];

        const kioskOptions = getKioskOptionsFromConfig(config);
        if (kioskOptions.collectCustomerName || kioskOptions.collectCustomerEmail || hasQuestions || hasDeliveryOptions) {
            checkoutSteps.push({
                title: 'Registration',
                components: [
                    {
                        name: 'memberInfo',
                    },
                    {
                        name: 'nameOnTicket',
                    },
                    {
                        name: 'delivery',
                    },
                    {
                        name: 'upsells',
                        props: {
                            position: POSITION_EMBEDDED_IN_REGISTRATION,
                        }
                    },
                    {
                        name: 'questions',
                    },
                ],
            });
        }
        if (!isFree) {
            let step = {
                title: "Payment",
                components: [
                    {
                        'name': "TimeoutPromptContainer"
                    },
                ],
            };

            if(isDevMode()) {
                step.components.push({
                    'name': "payment"
                });
            } else {
                step.components.push({
                    'name': "StripeEmvPayment"
                });
            }
            checkoutSteps.push(step);
        }
        return checkoutSteps;
    } else {
        return [
            {
                title: 'Registration',
                components: [
                    {
                        name: 'memberInfo',
                    },
                    {
                        name: 'nameOnTicket',
                    },
                    {
                        name: 'questions',
                    },
                    {
                        name: 'delivery',
                    },
                    {
                        name: 'donation',
                    },
                    {
                        name: 'coupon'
                    },
                    {
                        name: 'upsells',
                        props: {
                            position: POSITION_EMBEDDED_IN_REGISTRATION,
                        }
                    },
                    {
                      name: 'insurance',
                    },
                    {
                      name: 'weather'
                    },
                ],
            },
            {
                title: 'Payment',
                components: [
                    {
                        name: 'payment',
                    },
                ],
            }
        ];
    }
}

export const getTicketPickerSettings = (isEmbeddedPicker = false) => {
    return createSelector(
        [
            getListingConfig,
            getEvent,
            getAllState,
        ],
        (
            config,
            event,
            allState
        ) => {
            //if we ever make it to this state, render an empty, but recoverable ticketpicker
            if (Object.values(event.events)[0]?.event.isRecurring && !hasSelectedChildEvent({event})) {
                return {
                    components: [
                        {
                            name: "TicketPickerContainer",
                            children: [
                                {
                                    name: 'InventoryHeaderContainer',
                                },
                                {
                                    name: 'InventoryFooterContainer',
                                }
                            ],
                        },
                    ],
                };
            }
            let settings = {};
            if(config && config.ticketPicker) {
                settings = config.ticketPicker;
            } else {
                settings = getDefaultTicketPickerSettings(config, isEmbeddedPicker, allState);
            }

            return settings;
        }
    );
}

export const getPickerWithDetailsSettings = createSelector(
  [getAllState],
  (state) => {
    const bannerImage = state.ui.uiConfig.images.embed_image;

    const isSeated = isSeatedListing(state);

    const commonActions = [
      {
        name: "CouponEntryButton",
      },
      {
        name: "ContinueShoppingButton"
      }
    ]

    const regularTicketPickerActions = [
      {
        name: 'CheckoutButton'
      },
    ];
    const seatedAcions = [
      {
        name: 'BestAvailableOrPyosPicker'
      }
    ];

    const pickerActionsChildren = isSeated ? seatedAcions : regularTicketPickerActions;

    return {
      components: [
        {
          name: 'PickerWithDetailsMain',
          children: [
            !isSeated && {
              name: 'InventoryContentContainer',
              children: [
                {
                    name: 'AlertContainer',
                },
                {
                  name: 'InventoryItemsContainer'
                },
              ]
            },
            {
              name: 'InventoryErrors'
            },
            {
              name: 'PickerWithDetailsActions',
              children: [
                ...pickerActionsChildren,
                ...commonActions
              ]
            }
          ].filter(Boolean),
          props: {
            bannerImage
          }
        }
      ]
    }
  })

function getDefaultTicketPickerSettings(config, isEmbeddedPicker, allState) {

    const InventoryHeaderComponent = {
        name: 'InventoryHeaderContainer',
        props: {
            hide: !!isEmbeddedPicker,
        }
    };

    if (config.layout === 'kiosk') {
        return {
            components: [
                {
                    name:"KioskLayoutContainer",
                    props:{
                        splitPane: true,
                        couponAlerts: true,
                        inventoryErrors: true,
                    },
                    children: [
                        {
                            name: 'InventoryContentContainer',
                            children: [
                                {
                                    name: 'InventoryItemsContainer',
                                    children: [
                                        {
                                            name: 'PriceLevelWrapper',
                                            // not supporting reserved seating in kiosk for now
                                            // props: {
                                            //     showBestAvailablePicker: showBestAvailablePicker(allState),
                                            // },
                                            children: [
                                                {
                                                    name: 'ChangeDateContainer',
                                                }
                                            ]
                                        },
                                    ]
                                },
                            ]
                        },
                    ],
                }
            ],
        };
    }

    const isSeated = isSeatedListing(allState);

    if (isSeated) {
        const supportsPyos = isPyos(allState);

        // if this listing supports best available and pyos, and the user has not selected one
        // show a view where the user can select which flow they want to follow
        if (supportsPyos && !allState.ui.showBestAvailablePicker && !allState.ui.showPyosPicker && !hasAppliedReserveCoupon(allState)) {
            return {
                components: [
                    {
                        name: "TicketPickerContainer",
                        props: {
                            focusTrap: false,
                            isEmbeddedPicker,
                        },
                        children: [
                            InventoryHeaderComponent,
                            {
                                name: 'BestAvailableOrPyosPicker',
                            },
                        ],
                    },
                ],
            };
        }

        // either the user selected PYOS or this listing only supports PYOS
        if (showPyosPicker(allState)) {
            return {
                components: [
                    {
                        name: "TicketPickerContainer",
                        props: {
                            focusTrap: false,
                            isSeatingChart: true,
                            isEmbeddedPicker,
                        },
                        children: [
                            {
                                name: 'CouponAlertsContainer',
                            },
                            InventoryHeaderComponent,
                            {
                                name: 'AlertContainer',
                            },
                            {
                                name: 'SeatingChartTicketPickerContainer',
                            },
                        ],
                    },
                ],
            };
        }
    }

    return {
        components: [
            {
                name: "TicketPickerContainer",
                props: {
                    isEmbeddedPicker,
                },
                children: [
                    {
                        name: 'CouponAlertsContainer',
                    },
                    InventoryHeaderComponent,
                    {
                        name: 'AlertContainer',
                    },
                    {
                        name: 'InventoryContentContainer',
                        children: [
                            {
                                name: 'ChangeDateContainer'
                            },
                            {
                                name: 'InventoryItemsContainer',
                            },
                        ]
                    },
                    {
                        name: 'InventoryFooterContainer',
                        children: [
                            {
                                name: 'InventoryErrors',
                            },
                            {
                                name: 'FeeDisclosure',
                            },
                            {
                                name: 'CheckoutButton',
                            },
                            {
                                name: "CouponEntryButton",
                            },
                        ],
                    },
                ],
            },
        ],
    };
}

export const getCustomInventory = createSelector(
    [
        getCheckoutConfig,
        getAccessInventory,
    ],
    (
        checkoutConfig,
        accessInventory
    ) => {
        if (accessInventory) {
            return accessInventory;
        }
        return checkoutConfig.inventory?.length ? checkoutConfig.inventory : null;
    }
);

export const getConfirmationPageSettings = createSelector(
    [
        getListingConfig,
    ],
    (
        config,
    ) => {
        let settings = {};
        if(config && config.confirmation) {
            settings = config.confirmation;
        } else {
            return getDefaultConfirmationSettings(config);
        }

        return settings;
    }
);

function getDefaultConfirmationSettings(config) {
    if(config.layout === "kiosk") {
        return {
            components: [
                {
                    'name': "ConfirmationHeader",
                },
                {
                    'name': "KioskConfirmationContainer",
                },
                {
                    'name': "KioskGoBackButton",
                },
                {
                    'name': "GoBackTimeout",
                },
                {
                    'name': "SidekickTicketPrint",
                },
            ],
        };
    } else {
        return {
            components: [
                {
                    name: 'ConfirmationContainer',
                },
            ]
        };
    }
}

export const getConfirmationConfig = createSelector([getUiConfig], (uiConfig) => {
    let config = {};

    if(uiConfig && uiConfig.checkout && uiConfig.checkout.confirmation) {
        config = uiConfig.checkout.confirmation;
    }

    return config;
});

export function getKioskOptionsFromConfig(config) {
    return config.checkout && config.checkout.kioskOptions ? config.checkout.kioskOptions : {};
}

export const getKioskOptions = createSelector(
    [
        getListingConfig,
    ],
    (
        config,
    ) => {
        return getKioskOptionsFromConfig(config);
    }
);

function getCmsModeFromConfig(config) {
    return config.checkout && config.checkout.cmsMode ? config.checkout.cmsMode : false;
}

export const getCmsMode = createSelector(
    [
        getListingConfig,
    ],
    (
        config,
    ) => {
        return getCmsModeFromConfig(config);
    }
);

export const isCurrentRouterState = (state) => {
    return createSelector([getRouter], (router) => {
        if (router.last && typeof router.last.$to === 'function') {
            const $to = router.last.$to();
            if (typeof $to === 'object' && $to.name === state) {
                return true;
            }
        }
        return false;
    });
}

export const getFeeDisplayOption = createSelector(
    [
        getCheckoutConfig,
    ],
    (
        checkoutConfig,
    ) => {
        const options = [TICKET_FEE_SEPARATE, TICKET_PRICE_ONLY];
        if (checkoutConfig.feeDisplayOption && options.indexOf(+checkoutConfig.feeDisplayOption) !== -1) {
            return +checkoutConfig.feeDisplayOption;
        }
        return TICKET_FEE_SEPARATE; //default
    }
);

export const shouldPinEmbeddedCart = createSelector(
    [
        getIsEmbed,
        getEmbedCartBehavior,
    ],
    (
        isEmbed,
        embedCartBehavior
    ) => {
        return isEmbed &&
            [EMBED_CART_BEHAVIOR_PIN_TO_TOP, EMBED_CART_BEHAVIOR_PIN_TO_BOTTOM].includes(embedCartBehavior);
    }
);

export const shouldHideEmbeddedCart = createSelector(
    [
        getIsEmbed,
        getEmbedCartBehavior,
    ],
    (
        isEmbed,
        embedCartBehavior
    ) => {
        return isEmbed &&
            [EMBED_CART_BEHAVIOR_HIDE].includes(embedCartBehavior);
    }
);

export const showContinueCheckoutBanner = createSelector(
    [
        reservationCompleted,
        overlay,
        hasReservation,
        getCaptureMethod,
    ],
    (
        reservationIsCompleted,
        overlayShown,
        doesHaveReservation,
        captureMethod
    ) => {
        if (
            reservationIsCompleted ||
            isCaptureMethodKioskMode(captureMethod) ||
            overlayShown ||
            !doesHaveReservation
        ) {
            return false;
        }
        return true;
    }
);

export function isDevMode() {
    return false;
}

export const isRegistrationFieldsFilledAndValidated = createSelector(
    [state => state.member, areAllQuestionsCompleted, state => state.ui?.checkoutConfig],
    (member, areQuestionsCompleted, checkoutConfig) => {
        const isKiosk =  checkoutConfig?.captureMethod == 13;
        const hasMemberErrors = !!Object.values(member.errors || {}).filter(Boolean).length;

        let memberInfoFilled = false;
        if(isKiosk) {
            memberInfoFilled =
              (!checkoutConfig?.kioskOptions?.customerNameRequired || !!member?.name) &&
              (!checkoutConfig?.kioskOptions?.customerEmailRequired || !!member?.email);
        } else {
            memberInfoFilled = !!member.name && !!member?.email;
        }
        return memberInfoFilled && areQuestionsCompleted && !hasMemberErrors;
    }
)

// Extandable, same validation can be applied to Payment step if needed.
export const isSubmitStepButtonDisabled = createSelector(
    [getCurrentCheckoutStep, isRegistrationFieldsFilledAndValidated],
    (currentStep, isRegistrationCompleted) => {
        if (currentStep.title === 'Registration') {
            return !isRegistrationCompleted;
        }
    }
)

export const selectEventNameFromListingConfig = createSelector(
  getListingConfig,
  (config) => config?.listings?.title
);
