import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import ListingBranding from './ListingBranding';
import ListingBannerProps from './ListingBannerComponent';
import { DateTimeFormatters } from '@showclix-projects/sc-intl';
import { ISOStringToDate, isValidDateObject } from 'helpers/time';
import TicketsGoOnSale from './TicketsGoOnSale';
import { getMainEvent } from '../../checkout/selectors/event'
import SelectLanguage from "./SelectLanguage";
import { getEventDate } from '@h/helpers';
import Translation from '@s/Translation';
import SalesPaused from "./SalesPaused";


class ListingBanner extends Component<ListingBannerProps> {
    getTitle = () => {
        let title = this.props.name;
        if (this.props.uiConfig.listings && this.props.uiConfig.listings.useEventTitle !== 'undefined' &&
            this.props.uiConfig.listings.useEventTitle === false) {
            title = this.props.uiConfig.listings.title;
        }

        return title;
    };

    getIsHideEventDateAndTime = () => this.props.uiConfig.hide_event_date_and_time;

    getIsHideEventTime = () => this.props.uiConfig.hide_event_time;

    isCustomTimeUsed = () =>
      this.props.uiConfig.listings.useEventTime !== 'undefined' &&
      this.props.uiConfig.listings.useEventTime === false;

    getTime = () => {
        if (
            this.props.uiConfig.listings && this.isCustomTimeUsed()
        ) {
            return this.props.uiConfig.listings.time;
        }

        if (this.props.isRecurring) {
          if (this.props.uiConfig.hide_event_date_and_time){
            return null;
          }

          let startDate = ISOStringToDate(this.props.start);
          if (!isValidDateObject(startDate)) {
            return null;
          }

          let endDate = null;
          if(this.props.end) {
              endDate = ISOStringToDate(this.props.end);
          }

          const {monthDayShort, monthDayYearShort} = DateTimeFormatters;

          if (!isValidDateObject(endDate)) {
              return monthDayShort(this.props.locale, startDate);
          }

          let startingYear = startDate.getFullYear();
          let endingYear = endDate.getFullYear();
          if(startingYear == endingYear){
              return `${monthDayShort(this.props.locale, startDate)} - ${monthDayYearShort(this.props.locale, endDate)}`;
          } else {
              return `${monthDayYearShort(this.props.locale, startDate)} - ${monthDayYearShort(this.props.locale, endDate)}`;
          }
        }
        return getEventDate(this.props.start, this.props.end, this.props.locale, this.getIsHideEventTime(), this.getIsHideEventDateAndTime());
    };

    getLocation = () => {
        if (this.props.uiConfig.hide_venue){
          return null;
        }

        let location = this.props.venueName + ", " + this.props.venueAddress;
        if(this.props.uiConfig.listings && this.props.uiConfig.listings.useEventLocation !== 'undefined' &&
            this.props.uiConfig.listings.useEventLocation === false) {
            location = this.props.uiConfig.listings.location;
        }

        return location;
    };

    getTimezone = () => {
      if(this.isCustomTimeUsed() || this.getIsHideEventTime() || this.getIsHideEventDateAndTime()) {
        return null;
      }

      return this.props.timezone;
    }

    render() {
        const headerContainerStyle = this.props.headerImageMaxWidth && +this.props.headerImageMaxWidth > 0
            ? {maxWidth: this.props.headerImageMaxWidth + 'px'}
            : {maxWidth: 'none'};

        return(
            <>
            <SelectLanguage className='select-language--listing' />
            <div className="page__banner">
                <div className="banner">
                    <ListingBranding slug={this.props.slug} brand={this.props.brand}/>
                    <div className="banner__content">
                        <div className="page-content__inner">
                            {this.props.headerImage !== "" && <div className="banner__logo" style={headerContainerStyle}>
                                <img className="banner__logo-img" alt={this.getTitle()} src={this.props.headerImage} />
                            </div>}
                            <div className="banner__event-details">
                                <h1 className="listing__title">{this.getTitle()}</h1>
                              <div className="event-details">
                                <div className="event-details__date">
                                  <span className="event-details__date-1">{this.getTime()} {this.getTimezone()}</span>
                                </div>
                                <div className="event-details__location">
                                  <address>
                                    <span className="event-details__location-name">{this.getLocation()}</span><br />
                                  </address>
                                </div>
                                {!this.props.hideAgeLimit && (
                                  <div className="event-details__location">
                                    <Translation>{this.props.ages}</Translation>
                                  </div>
                                )}
                                <TicketsGoOnSale />
                                <SalesPaused />
                              </div>
                              {this.renderTicketsButton()}
                            </div>
                        </div>
                    </div>
                  <div className="banner__background-image">
                    </div>
                </div>
            </div>
            </>
        )
    }

    renderTicketsButton = () => {
        return this.props.children;
    }
}

const mapStateToProps = (state, props) => {

    const venue = state.venue.venues ? Object.values(state.venue.venues)[0] : null;
    const mainEvent = getMainEvent(state);
    return {
        locale: state.intl.locale,
        end: mainEvent?.event?.end ?? '',
        name: mainEvent?.event?.name ?? '',
        start: mainEvent?.event?.start ?? '',
        venueName: venue ? venue.name : "",
        venueAddress: venue ? venue.address.city + ', ' + venue.address.region : "",
        ages: mainEvent?.event?.age_minimum,
        hideAgeLimit: mainEvent?.event?.hide_age_limit,
        slug: state.partner.slug,
        brand: state.partner.brand,
        uiConfig: state.ui.uiConfig,
        headerImage: (state.ui.uiConfig && state.ui.uiConfig.images) ? state.ui.uiConfig.images.header : null,
        headerImageMaxWidth: (state.ui.uiConfig && state.ui.uiConfig.images && state.ui.uiConfig.images.headerImageMaxWidth) ? state.ui.uiConfig.images.headerImageMaxWidth : null,
        isRecurring: !!mainEvent?.event?.isRecurring,
        timezone: mainEvent.event.timezone
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
    }, dispatch);
}

ListingBanner.defaultProps = {
    headerImage: "",
    isOnsale: false
}

ListingBanner.propTypes = {
    locale: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string,
    branding: PropTypes.string,
    start: PropTypes.string.isRequired,
    end: PropTypes.string,
    venueName: PropTypes.string.isRequired,
    venueAddress: PropTypes.string.isRequired,
    headerImage: PropTypes.string,
    isRecurring: PropTypes.bool,
    timezone: PropTypes.string
}

export default connect(mapStateToProps, mapDispatchToProps)(ListingBanner)
