import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import { DateTimeFormatters } from '@showclix-projects/sc-intl';
import { ISOStringToDate } from 'helpers/time';
import { getMainEvent } from '../../checkout/selectors/event'
import Translation from "../../shared/Translation";

class TicketsGoOnSale extends Component<TicketsGoOnSaleProps> {
    getOnsaleTime = () => {
        let onsale = this.props.uiConfig.listings.hideEventEndMessage ? '' :<Translation>{"checkout__tickets_not_currently_on_sale"}</Translation>;
        if (this.props.isCanceled) {
            onsale = <Translation>{"checkout__event_has_been_canceled"}</Translation>;
            return onsale;
        }

        let timezone = '';
        if(this.props.events) {
          const [event] = (Object.values(this.props.events));
          timezone = event.event.timezone;
        }
        if(!this.props.isPaused && this.props.uiConfig.listings && (this.props.uiConfig.listings.presaleOverride !== undefined)) {
          return this.props.uiConfig.listings.presaleOverride;
        }
        if (!this.props.isPaused && this.props.uiConfig.listings && this.props.uiConfig.listings.displayOnsaleTime !== undefined) {
            if (this.props.uiConfig.listings.displayOnsaleTime === true && this.props.onsaleDate !== null) {
                const { fullDateShort } = DateTimeFormatters;

                let onsaleDate = new Date(this.props.onsaleDate);
                if (onsaleDate.toString() == "Invalid Date") {
                    onsaleDate = ISOStringToDate(this.props.onsaleDate);
                }

                if (onsaleDate >= new Date()) {
                    const localeOnsale = fullDateShort(this.props.locale, onsaleDate);
                    onsale = <Translation interpolation={{ localeOnsale, timezone }}>{"checkout__tickets_go_on_sale_with_tz"}</Translation>;
                }
            }
        }

        return onsale;
    };

    render() {
        if (this.props.isOnsale || this.props.inviteCouponApplied || (this.props.uiConfig.listings.hideEventEndMessage && !this.getOnsaleTime())) {
            return null;
        }

        return(
          <div className="event-details__ticket-go-on-sale">
            <address>
                <span className="event-details__location-name">{this.getOnsaleTime()}</span><br />
            </address>
          </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    const mainEvent = getMainEvent(state);
    return {
        locale: state.intl.locale,
        isOnsale: !!mainEvent?.event?.isOnsale,
        isPaused: !!mainEvent?.event?.isPaused,
        isCanceled: !!mainEvent?.event?.isCanceled,
        uiConfig: state.ui.uiConfig,
        onsaleDate: mainEvent?.event?.onsaleDate ?? '',
        inviteCouponApplied: state.couponTag.appliedTaggableInvitationCode || state.reservation.hasInviteCoupon,
        events: state.event.events
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
    }, dispatch);
}

TicketsGoOnSale.propTypes = {
    locale: PropTypes.string.isRequired,
    isOnsale: PropTypes.bool.isRequired,
    isCanceled: PropTypes.bool.isRequired,
    onsaleDate: PropTypes.string,
    events: PropTypes.object.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(TicketsGoOnSale)
